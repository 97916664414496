<template>
  <b-container class="my-4">
    <b-row class="text-center mb-4" align-h="center" v-if="!permission.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row v-if="permission.read">
      <b-col>
        <b-card >
          <b-container>
            <b-row>
              <b-col sm="12" md="8">
                <router-link :to="{ name: 'Trainingcase', params: { id: item.id }}"><h2>{{item.name}}</h2></router-link>
                <h3 v-if="item.body">Description</h3>
                <div v-if="item.body" v-html="item.body"></div>
              </b-col>
            </b-row>
            <b-row v-if="item.risks.length > 0 || item.controlrisks.length > 0 || item.controls.length > 0 || item.obligations.length > 0 || item.publications.length > 0">
              <b-col>
                <b-collapse v-model="details">
                  <div v-if="item.controlrisks.length > 0">
                    <h3 v-if="item.body">Control risks</h3>
                    <div v-for="row in item.controlrisks" :key="'controlrisk'+row.id">
                      <strong>{{row.name}}</strong>
                      <div>{{row.description}}</div>
                    </div>
                  </div>
                  <div v-if="item.risks.length > 0">
                    <h3 v-if="item.body">Market asbue risks</h3>
                    <div v-for="row in item.risks" :key="'marrisk'+row.id">
                      <b-link :to="{ name: 'Risk', params: { id: row.id } }"><strong>{{row.name}}</strong></b-link>
                      <div>{{row.comments}}</div>
                    </div>
                  </div>
                  <div v-if="item.controls.length > 0">
                    <h3 v-if="item.body">Controls</h3>
                    <div v-for="control in item.controls" :key="'control'+control.id">
                      <b-link :to="{ name: 'Control', params: { id: control.id } }">{{control.name}}</b-link>
                    </div>
                  </div>
                  <div v-if="item.obligations.length > 0">
                    <h3 v-if="item.body">Obligations</h3>
                    <div v-for="row in item.obligations" :key="'obligation'+row.id">
                      <b-link :to="{ name: 'RegDecompObligation', params: { id: row.id } }">{{row.name}}</b-link>
                    </div>
                  </div>
                  <div v-if="item.publications.length > 0">
                    <h3 v-if="item.body">News</h3>
                    <div v-for="row in item.publications" :key="'newsitem'+row.id">
                      <b-link :to="{ name: 'NewsItem', params: { id: row.id } }">{{row.name}}</b-link>
                    </div>
                  </div>
                </b-collapse>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import moment from 'moment'

export default {
  name: 'Trainingcase',
  props: ['details', 'item'],
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.permission.read = ac.can(this.user.acgroups).readAny('trainingcase').granted
  },
  data () {
    return {
      moment: moment,
      permission: {
        read: false
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped>
h3 {
  margin-top: 1.5em;
}
h4 {
  margin-top: 1.5em;
}
</style>
